.slick-prev:before,
.slick-next:before {
    color: #bcdefa;
    font-size: 40px;
    opacity: 0.8;
}

.slick-prev {
    left: 30;
    z-index: 100;
    transform: scale(1.3);
}
.slick-next {
    right: 0;
    transform: scale(1.3);
}
.slick-dots {
    position: relative;
}
